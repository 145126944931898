<template>
  <v-form v-model="valid" ref="form" lazy-validation>
    <v-dialog v-model="modal" max-width="550" persistent>
      <v-card>
        <v-card-title class="headline">
          {{ $t("class_list.delete_force") }} {{ className }}
        </v-card-title>
        <v-divider />
        <v-card-text class="mt-2">
          <b> {{ className }} </b>
          <span class="red--text">{{ $t("subjects.cannot_delete") }}</span>
          {{ $t("subjects.has_been_relation") }}
          <b> {{ totalRelations }} Data </b>.

          <v-simple-table class="mt-2 mb-4">
            <template v-slot:default>
              <tbody>
                <tr>
                  <th>Data</th>
                  <th>Total</th>
                </tr>
                <tr>
                  <td>{{ $t("app.student") }}</td>
                  <td>{{ dataRelations.student }}</td>
                </tr>
                <tr>
                  <td>{{ $t("routes.schedule") }}</td>
                  <td>{{ dataRelations.schedule }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>

          <div class="font-weight-bold mt-2">
            {{ $t("class_list.confirm_password") }}
          </div>

          <v-text-field
            v-model="passwordConfirm"
            :type="showPwd ? 'text' : 'password'"
            :append-icon="showPwd ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="
              formValidation(
                {
                  a: $t('login.password_rules'),
                  b: $t('login.password_rules_minlength')
                },
                'password'
              )
            "
            prepend-inner-icon="mdi-lock"
            dense
            class="mt-3"
            outlined
            label="Password"
            @keyup.enter="forceDelete"
            @click:append="showPwd = !showPwd"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            :disabled="loadingForceDelete"
            outlined
            color="primary"
            class="mr-2"
            @click="close()"
          >
            {{ $t("app.cancel") }}
          </v-btn>
          <v-btn
            :loading="loadingForceDelete"
            color="gradient-primary white--text"
            depressed
            @click="forceDelete"
          >
            {{ $t("app.save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-form>
</template>

<script>
import { delete_class } from "@/api/admin/academic/classList";

export default {
  props: {
    className: String,
    subjectsConflict: Object,
    modal: Boolean,
    bodyDelete: Object,
    dataRelations: Object,
    totalRelations: Number // required if isMaster true
  },
  data() {
    return {
      showPwd: false,
      loadingForceDelete: false,
      valid: false,
      passwordConfirm: ""
    };
  },
  methods: {
    close() {
      this.passwordConfirm = "";
      this.$refs.form.resetValidation();
      this.$emit("close");
    },
    async forceDelete() {
      if (this.$refs.form.validate()) {
        this.loadingForceDelete = true;
        delete_class({
          ...this.bodyDelete,
          force: true,
          password: this.passwordConfirm
        })
          .then(res => {
            if (res.data.code) {
              this.snackBar(
                true,
                this.$i18n.t(
                  `subjects.msg_response.${
                    this.isMaster ? "master_subjects" : "subjects"
                  }.success_deleted`
                )
              );
              this.close();
            } else {
              this.snackBar(false, res.data.message);
            }
            this.loadingForceDelete = false;
          })
          .catch(error => {
            console.error("forceDelete()\n", error);
            this.loadingForceDelete = false;
          });
      }
    },
    snackBar(isSuccess, msg) {
      this.$store.commit("CALL_SNACKBAR", {
        msg: msg,
        color: isSuccess ? "success" : "error"
      });
    },
    formValidation(text, type) {
      switch (type) {
        case "password":
          return [v => !!v || text.a, v => v.length >= 6 || text.b];
        default:
          return [v => !!v || text];
      }
    }
  }
};
</script>
